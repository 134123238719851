import React, { FunctionComponent } from 'react';
import { NotFound, Layout } from '../components';

const NotFoundPage: FunctionComponent = () => (
  <Layout>
    <NotFound />
  </Layout>
);

export default NotFoundPage;
